<template>
  <div class="paymentCard">
    <div v-if="loading">
      <h5 class="card-title">Online Betalen</h5>
      <spinner size="medium" :line-fg-color="company.color"></spinner>
    </div>
    <div v-else>
      <div v-if="payment">
        <h5 class="card-title">{{ payment.description }}</h5>
        <h6 class="card-subtitle mb-2 text-muted">
          <small>Onze betalingsreferentie:</small> {{ payment.reference }}
        </h6>
        <br />
        <h5 class="card-title right">&euro; {{ payment.parsedAmount }}</h5>
        <div v-if="payment.status === 'paid'">
          Deze factuur is al betaald. Dank u wel!
        </div>
        <div v-else-if="selected && selected.type === 'ideal'">
          <h6 class="card-subtitle mb-2 mt-3">Betalen via iDeal</h6>
          <p>Kies uw bank.</p>
          <br />
          <div class="container">
            <iDealSelector :intent="payment.intent" :reference="reference" />
          </div>
          <br />
          <button type="button" class="btn btn-link" v-on:click="resetMethod()">
            Terug
          </button>
        </div>
        <div v-else-if="selected && selected.type === 'manual_bank'">
          <h6 class="card-subtitle mb-2 mt-3">
            Betalen via bankoverschrijving
          </h6>
          <p>
            U kunt het bedrag (<b>&euro; {{ payment.parsedAmount }}</b
            >) overmaken via uw eigen bank.<br />Vul daarbij de volgende
            gegevens in:
          </p>
          <br />
          <table class="table">
            <tr>
              <th scope="row">Omschrijving</th>
              <td>{{ payment.reference }}</td>
            </tr>
            <tr>
              <th scope="row">IBAN</th>
              <td>{{ selected.iban }}</td>
            </tr>
            <tr>
              <th scope="row">Ten name van:</th>
              <td>{{ selected.ibanTnv }}</td>
            </tr>
          </table>
          <div class="alert alert-warning" role="alert">
            Het duurt mogelijk enkele dagen voordat uw betaling via deze methode
            is verwerkt.
          </div>
          <button type="button" class="btn btn-link" v-on:click="resetMethod()">
            Terug
          </button>
        </div>
        <div v-else>
          <div class="container">
            <div
              class="row"
              v-for="chunk in paymentMethodChunks"
              :key="'p' + chunk[0].type"
            >
              <div class="col-4" v-for="item in chunk" :key="item.description">
                <div
                  class="card method"
                  v-if="item"
                  v-on:click="openMethod(item)"
                >
                  <div class="card-body">
                    <img :src="item.image" :alt="item.description" />
                    <p>{{ item.description }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <h5 class="card-title">Online Betalen</h5>
        <p>
          De door uw opgegeven betalingsreferentie (<b>{{ reference }}</b
          >) bestaat niet in ons systeem. Probeer het a.u.b. opnieuw.
        </p>
        <br />
        <base-button type="default" v-on:click="back()"
          >Terug naar het basisscherm</base-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import common from "../common";
import axios from "axios";
import Spinner from "vue-simple-spinner";
import iDealSelector from "../components/iDealSelector.vue";
import _ from "lodash";

const d = {
  name: "PaymentUI",
  components: {
    Spinner,
    iDealSelector,
  },
  data: () => ({
    payment: undefined,
    loading: true,
    reference: null,
    selected: null,
    ...common,
  }),
  computed: {
    paymentMethodChunks() {
      return _.chunk(Object.values(this.payment.paymentMethods), 3);
    },
  },
  mounted: function () {
    // Do a request to the server to get the data for this payment
    const id = this.$route.params.id;
    this.reference = id.replace('.', '');
    if (id !== undefined) {
      // Get the payment
      this.getPayment();
    }
  },
  methods: {
    getPayment() {
      axios
        .get(common.apiUrl + "/public/v1/payment/" + this.reference)
        .then((data) => {
          this.payment = data.data;
        })
        .catch(() => {
          console.log("Failed to load");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    back() {
      this.$router.push({ name: "PaymentUISelector" });
    },
    openMethod(item) {
      if (item.type === "ideal" || item.type === "manual_bank") {
        this.selected = item;
      } else {
        this.submit(item.type);
      }
    },
    resetMethod() {
      this.selected = null;
    },
  },
};
export default d;
</script>

<style lang="scss">
.paymentCard {
  position: relative;

  .right {
    float: right;
    position: absolute;
    top: 0;
    right: 0;
  }

  .issuer-item {
    img {
      height: 24px;
      margin-right: 20px;
    }

    &:hover {
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.21);
      cursor: pointer;
      transition: box-shadow 0.5s;
    }
  }

  .row {
    margin-bottom: 20px;

    @media (max-width: 960px) {
      .col-4 {
        width: 100%;
        flex: none;
        max-width: 100%;
        margin: 10px 0;
      }
    }
  }

  .method {
    &:hover {
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.21);
      cursor: pointer;
      transition: box-shadow 0.5s;
    }

    img {
      display: block;
      height: 48px;
      margin: 5px auto 10px;
    }

    p {
      text-align: center;
      font-weight: 600;
      margin: 0;
    }
  }
}
</style>