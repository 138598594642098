import Vue from 'vue'
import VueRouter from 'vue-router'
import PaymentUI from '../views/Default.vue'
import PaymentUIComplete from '../views/Complete.vue'
import PaymentUISelector from '../views/Selector.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'PaymentUISelector',
    component: PaymentUISelector
  },
  {
    path: '/complete/:id',
    name: 'PaymentUIComplete',
    component: PaymentUIComplete
  },
  {
    path: '/:id',
    name: 'PaymentUI',
    component: PaymentUI
  }
]

const router = new VueRouter({
  routes
})

export default router
