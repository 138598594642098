<template>
  <div class="selector">
    <h5 class="card-title">Online Betalen</h5>
    <h6 class="card-subtitle mb-2 text-muted">
      Vul uw betalingsreferentie in.
    </h6>
    <br />

    <base-input v-model="reference"></base-input><br />
    <base-button type="primary" v-on:click="submit()">Betalen</base-button>

    <div class="help">
      <p>
        <b>Waar vind ik mijn betalingsreferentie?</b><br />
        Uw betalingsreferentie staat vermeld op uw factuur.
      </p>
    </div>
  </div>
</template>

<script>
const d = {
  name: "PaymentUISelector",
  data: () => ({
    reference: null,
  }),
  methods: {
    submit() {
      this.$router.push({ name: "PaymentUI", params: { id: this.reference } });
    },
  },
};
export default d;
</script>

<style lang="scss">
.selector {
  .help {
    margin-top: 50px;
  }
}
</style>