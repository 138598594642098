<template>
  <div>
    <div id="ideal-bank-element"></div>
    <br />
    <button type="button" class="btn btn-primary" v-on:click="submit()">
      Betalen
    </button>
  </div>
</template>

<script>
import common from "../common";
const stripe = window.Stripe(common.paymentToken);

const d = {
  name: "iDealElement",
  data: function () {
    return {
      idealBank: null,
    };
  },
  props: {
    intent: String,
    reference: String,
  },
  mounted: function () {
    const elements = stripe.elements();
    const options = {
      // Custom styling can be passed to options when creating an Element
      style: {
        base: {
          padding: "10px 12px",
          color: "#32325d",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
      },
    };
    const idealBank = elements.create("idealBank", options);
    idealBank.mount("#ideal-bank-element");
    this.idealBank = idealBank;
  },
  methods: {
    submit() {
      // Redirects away from the client
      const url = new URL(window.location.href);
      url.hash = "/complete/" + this.reference;

      stripe
        .confirmIdealPayment(this.intent, {
          payment_method: {
            ideal: this.idealBank,
          },
          return_url: url.toString(),
        })
        .then(function (result) {
          console.log(result);
          if (result.error) {
            // Inform the customer that there was an error.
          }
        });
    },
  },
};
export default d;
</script>