<template>
  <div class="app">
    <div class="app-container">
      <img class="logo" :src="company.logo" alt="Logo" />
      <div class="card">
        <div class="card-body">
          <router-view/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import common from "./common";

export default {
  data: () => ({
    ...common
  })
}
</script>

<style lang="scss">
html,body {
  width: 100%;
  height: 100%;
  background-color: #f4f4f4 !important;
  box-sizing: border-box;
}

.app {
  padding: 10vh;
  background-color: inherit;

  @media(max-width: 960px) {
    padding: 20px;
  }
}

.app-container {
  margin: 0 auto;
  max-width: 600px;

  @media(max-width: 960px) {
    width: 100%;
  }
}

.logo {
  width: 250px;
  display: block;
  margin: 0 auto 20px;
}

</style>
