<template>
  <div class="home">
    <h5 class="card-title">Online Betalen</h5>
    <h6 class="card-subtitle mb-2 text-muted">Betaling voltooid!</h6>
    <br />
    <p>
      Uw betaling is voltooid. We werken op de achtergrond de status in onze systemen bij.
    </p>
    <p>
      Als u op dit scherm komt na een mislukte betaling, kunt u het via de link
      hieronder nogmaals proberen.
    </p>
    <br />
    <base-button type="default" v-on:click="tryAgain()"
      >Nogmaals proberen</base-button
    >
  </div>
</template>

<script>
export default {
  name: "PaymentUIComplete",
  methods: {
    tryAgain() {
      this.$router.push({
        name: "PaymentUI",
        params: { id: this.$route.params.id.replace('.', '') },
      });
    },
  },
};
</script>
